<template>
  <div class="home">
    <h3>欢迎来到科伦健康战略发展研究中心！</h3>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
<style scoped lang="less">
.home {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding-top: 100px;
  background: #fff;
}
</style>
